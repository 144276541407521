body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.normal {
  width: 100%;
  overflow-y: scroll;
}
.header {
  position: relative;
  height: 50vw;
  background: linear-gradient(180deg, #2bc0a1 0%, #b5eab3 100%);
  display: flex;
  align-items: center;
  padding: 0 18.8vw;
}
.header .bg1 {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
}
.header .logo {
  background: #089083;
  width: 100%;
  height: 4.7vw;
  display: flex;
  align-items: center;
  padding-left: 18.8vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.header .logo .hImg {
  width: 14.3vw;
}
.header .info {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header .info ul {
  padding-inline-start: 20px;
}
.header .info .pc1 {
  width: 22vw;
}
.header .info .title1 {
  text-align: left;
  font-family: Roboto;
  font-weight: bold;
  color: #ffffff;
  font-size: 2.5vw;
  margin-bottom: 2vw;
}
.header .info .desc1 {
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 0.8vw;
  font-size: 1.6vw;
}
.header .info a span {
  visibility: hidden;
  float: right;
}
.header .info .download {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F5BF4C;
  border-radius: 1vw;
  cursor: pointer;
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 1vw;
  color: #FFFFFF;
  line-height: 1.5vw;
  padding: 0.7vw 1vw;
  width: 15vw;
}
.header .info .download .downImg {
  width: 0.9vw;
  margin-right: 0.6vw;
}
.header .info .gp {
  width: 20vw;
}
.header-mob {
  height: 60vh;
  padding: 0 35px;
  align-items: flex-start;
  padding-top: 69px;
}
.header-mob .logo {
  height: 47px;
  padding: 0 35px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.header-mob .logo .hImg {
  width: 100px;
  margin: 0 auto;
}
.header-mob .info {
  padding-top: 35px;
}
.header-mob .info .pc1 {
  width: 150px;
}
.header-mob .info .title1 {
  font-size: 18px;
  margin-bottom: 21px;
}
.header-mob .info .desc1 {
  font-size: 12px;
  margin-bottom: 14px;
}
.header-mob .info .download {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F5BF4C;
  border-radius: 6px;
  cursor: pointer;
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 8px;
  color: #FFFFFF;
  line-height: 11px;
  width: 120px;
  padding: 5px 5px;
}
.header-mob .info .download .downImg {
  width: 7px;
  margin-right: 3px;
}
.header-mob .info .gp {
  width: 180px;
}
.block2 {
  background-color: #fff;
  height: 80vw;
  position: relative;
  padding: 0 18.8vw;
  padding-top: 5vw;
}
.block2 .bg2 {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
}
.block2 .title21 {
  position: relative;
  z-index: 2;
  font-family: Roboto;
  font-weight: bold;
  font-size: 1.3vw;
  color: #4d4d4d;
  text-align: center;
}
.block2 .info1 {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6vw;
}
.block2 .info1 .card1 {
  position: relative;
  border-radius: 8px;
  border: 2px solid #2bc0a1;
  width: 18vw;
  padding: 2vw;
  padding-top: 4.5vw;
}
.block2 .info1 .card1 .icon1 {
  position: absolute;
  top: -3.5vw;
  left: 1.6vw;
  width: 7vw;
}
.block2 .info1 .card1 .desc21 {
  font-family: Roboto;
  font-weight: bold;
  font-size: 1.3vw;
  color: #2bc0a1;
}
.block2 .info1 .card1 .tip1 {
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.1vw;
}
.block2 .info2 {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  margin-top: 9vw;
}
.block2 .info2 .pc2 {
  width: 18vw;
}
.block2 .info2 .i2Right {
  margin-left: 3vw;
}
.block2 .info2 .i2Right .i2title {
  font-family: Roboto;
  font-weight: bold;
  font-size: 2.25vw;
  color: #089083;
  margin-bottom: 2.5vw;
}
.block2 .info2 .i2Right .i2Row {
  margin-left: 1vw;
  display: flex;
  align-items: center;
}
.block2 .info2 .i2Right .i2Row span:nth-child(1) {
  border-radius: 50%;
  width: 3vw;
  height: 3vw;
  font-size: 2vw;
  font-family: Roboto;
  font-weight: bold;
  color: #2bc0a1;
  border: 2px solid #2bc0a1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.block2 .info2 .i2Right .i2Row span:nth-child(2) {
  font-family: Roboto;
  font-weight: bold;
  font-size: 1.1vw;
  color: #4d4d4d;
  margin-left: 1.6vw;
}
.block2 .info2 .i2Right .i2RowLine {
  height: 2vw;
  margin-left: 2.5vw;
  border-left: 1px solid #2bc0a1;
}
.block2-mob {
  height: 120vh;
  padding: 0 35px;
  padding-top: 35px;
}
.block2-mob .title21 {
  font-size: 15px;
}
.block2-mob .info1 {
  justify-content: center;
  flex-direction: column;
  margin-top: 35px;
}
.block2-mob .info1 .card1 {
  width: auto;
  padding: 21px 35px;
  padding-top: 4.5vw;
  margin-bottom: 35px;
}
.block2-mob .info1 .card1 .icon1 {
  position: absolute;
  top: -18px;
  left: 1.6vw;
  width: 35px;
}
.block2-mob .info1 .card1 .desc21 {
  font-size: 15px;
}
.block2-mob .info1 .card1 .tip1 {
  font-size: 11px;
}
.block2-mob .info2 {
  margin-top: 0px;
}
.block2-mob .info2 .pc2 {
  width: 130px;
}
.block2-mob .info2 .i2Right {
  margin-left: 14px;
}
.block2-mob .info2 .i2Right .i2title {
  font-size: 12px;
}
.block2-mob .info2 .i2Right .i2Row {
  margin-left: 1vw;
}
.block2-mob .info2 .i2Right .i2Row span:nth-child(1) {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
}
.block2-mob .info2 .i2Right .i2Row span:nth-child(2) {
  font-size: 11px;
}
.block2-mob .info2 .i2Right .i2RowLine {
  height: 10px;
  margin-left: 14px;
}
.block3 {
  background-color: #fff;
  height: 86vw;
  position: relative;
  padding-top: 5vw;
}
.block3 .bg3 {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
}
.block3 .info31 {
  background: rgba(43, 192, 161, 0.39);
  border-radius: 16px;
  margin: 0 8.8vw;
  padding: 2vw 10vw;
  position: relative;
}
.block3 .info31 .desc31 {
  font-family: Roboto;
  font-weight: bold;
  font-size: 1.3vw;
  color: #4d4d4d;
  text-align: left;
}
.block3 .info31 a span {
  position: absolute;
  visibility: hidden;
  float: right;
}
.block3 .info31 .action {
  position: relative;
  background: rgba(255, 255, 255, 0.39);
  border-radius: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2vw 3vw;
  margin-top: 3.6vw;
}
.block3 .info31 .action .gp3 {
  width: 21vw;
}
.block3 .info31 .action .pc3 {
  position: absolute;
  right: 5.2vw;
  bottom: 0;
  width: 25vw;
}
.block3 .page3 {
  padding-top: 10vw;
  position: relative;
  z-index: 2;
}
.block3 .page3 .page3-title {
  font-size: 2.5vw;
  font-family: PingFang SC;
  font-weight: 600;
  color: #2bc0a1;
  text-align: center;
}
.block3 .page3 .page3-subtitle {
  font-size: 1.3vw;
  font-family: PingFang SC;
  font-weight: bold;
  color: #4d4d4d;
  text-align: center;
}
.block3 .page3 .allFaq {
  position: relative;
  margin: 0 18vw;
  display: flex;
  justify-content: space-between;
}
.block3 .page3 .allFaq .one-card {
  width: 20vw;
  height: 26vw;
  background: #ffffff;
  box-shadow: 1px 2px 8px #beeae1;
  border-radius: 5px;
  padding: 2.7vw 0;
}
.block3 .page3 .allFaq .one-card .cardImg {
  margin: 0 auto;
  text-align: center;
}
.block3 .page3 .allFaq .one-card .cardImg img {
  width: 3.6vw;
}
.block3 .page3 .allFaq .one-card .card-title {
  padding-top: 1vw;
  font-size: 0.9vw;
  font-family: PingFang SC;
  font-weight: bold;
  color: #24368c;
  text-align: center;
  margin-bottom: 0.9vw;
}
.block3 .page3 .allFaq .one-card .caed-detail .one-item {
  margin: 0 1.2vw;
  display: flex;
  font-size: 0.9vw;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 1.3vw;
  color: #24368c;
  text-align: left;
  padding-bottom: 0.9vw;
}
.block3 .page3 .allFaq .one-card .caed-detail .one-item .value {
  flex: 1 1;
  margin-left: 0.8vw;
}
.block3-mob {
  height: 110vh;
}
.block3-mob .info31 {
  margin: 0 35px;
  padding: 21px 35px;
}
.block3-mob .info31 .desc31 {
  font-size: 11px;
}
.block3-mob .info31 .action {
  padding: 14px 21px;
  margin-top: 35px;
}
.block3-mob .info31 .action .gp3 {
  width: 100px;
}
.block3-mob .info31 .action .pc3 {
  right: 21px;
  width: 80px;
}
.block3-mob .page3 {
  padding-top: 35px;
}
.block3-mob .page3 .page3-title {
  font-size: 18px;
}
.block3-mob .page3 .page3-subtitle {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}
.block3-mob .page3 .allFaq {
  justify-content: center;
  flex-direction: column;
  margin: 0 35px;
  margin-top: 14px;
}
.block3-mob .page3 .allFaq .one-card {
  width: 100%;
  height: auto;
  margin-top: 7px;
  padding: 21px 35px;
}
.block3-mob .page3 .allFaq .one-card .cardImg {
  position: absolute;
  left: -15px;
}
.block3-mob .page3 .allFaq .one-card .cardImg img {
  width: 35px;
}
.block3-mob .page3 .allFaq .one-card .card-title {
  padding-top: 0;
  font-size: 12px;
  text-align: left;
  margin-bottom: 7px;
}
.block3-mob .page3 .allFaq .one-card .caed-detail .one-item {
  margin: 0;
  font-size: 12px;
  line-height: 15px;
}
.block4 {
  background-color: #fff;
  height: 35vw;
  position: relative;
  padding-top: 5vw;
  display: flex;
  align-items: center;
  padding: 0 18.8vw;
}
.block4 .footer {
  position: absolute;
  bottom: 0;
  height: 3.4vw;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  background: #e7f4f0;
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 1vw;
  line-height: 3.4vw;
  color: #4d4d4d;
  text-align: center;
}
.block4 .bg4 {
  position: absolute;
  bottom: 3.4vw;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
}
.block4 .b4Logo {
  position: relative;
  z-index: 2;
  width: 10vw;
}
.block4 .b4Row {
  position: relative;
  z-index: 2;
  color: #fff;
  margin-left: 7vw;
  height: 10vw;
}
.block4 .b4Row .b4title {
  font-family: Roboto;
  font-weight: bold;
  font-size: 1.6vw;
  margin-bottom: 2.6vw;
}
.block4 .b4Row .b4desc {
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.3vw;
  padding-bottom: 1.6vw;
  color: #fff;
}
.block4 .b4Row .b4desc .b4link {
  color: #fff;
  text-decoration: underline;
}
.block4-mob {
  height: 50vh;
  padding: 0;
  padding-bottom: 35px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.block4-mob .footer {
  display: none;
}
.block4-mob .b4Logo {
  display: none;
}
.block4-mob .bg4 {
  height: 22vh;
  bottom: 0;
}
.block4-mob .b4Row {
  margin-left: 0;
}
.block4-mob .b4Row .b4title {
  display: none;
}
.block4-mob .b4Row .b4desc {
  font-size: 18px;
  padding-bottom: 7px;
}

body {
  background: #E7F4F0;
}
.contentWrapper {
  background: rgba(255, 255, 255, 0.5);
  padding: 35px;
  width: 900px;
  margin: auto;
}
.contentWrapper h1,
.contentWrapper h2 {
  font-size: 24px;
  text-align: center;
  color: #333;
  font-weight: 500;
}
.contentWrapper p {
  text-align: left;
  text-indent: 30px;
  font-size: 18px;
  line-height: 30px;
  color: #666;
}
@media only screen and (max-width: 830px) {
  .contentWrapper {
    background: rgba(255, 255, 255, 0.85);
    padding: 20px;
    width: 100%;
    margin: auto;
    border: 0;
    box-sizing: border-box;
  }
  .contentWrapper h1,
  .contentWrapper h2 {
    font-size: 24px;
    text-align: center;
    color: #333;
    font-weight: 500;
  }
  .contentWrapper p {
    text-align: left;
    text-indent: 30px;
    font-size: 18px;
    line-height: 30px;
    color: #666;
  }
}

